<template>
  <div class="models">
    <page-search
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('common.app-geo-fence-setting')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'
import { useRouter } from 'vue-router'
// 表格基础配置项
const tableOptions = {
  moduleName: 'geoFence', // 所属的模块
  pageName: 'setting', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  pageAction: 'geoFence/getGeoFenceSettingListByPage', // 获取表格的Action
  deleteRecordAction: 'geoFence/handleDeleteGeoFenceGroupSetting', // 删除record的Action
  pageCountGetter: 'geoFence/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'geoFence/pageListData' // 获取表格列表数据getters
}

const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')
const pageSearchRef = ref('')

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/geo-fence/setting/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/geo-fence/setting/edit/${id}`)
}

const handleNewData = () => {
  router.push(`/geo-fence/setting/new`)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
