import { getGlobalParamsOptionsAsync } from '@/utils/common'

export const searchFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name'
    },
    {
      field: 'tableId',
      type: 'select',
      label: 'general.router-geo-fence',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('geoFenceList', {
          isNeedPreFix: false
        })
    },
    {
      field: 'groupId',
      type: 'select',
      label: 'geoFence.geo-fence-group-name',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getGlobalParamsOptionsAsync('geoFenceGroupList', {
          isNeedPreFix: false
        })
    },
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
